<template>
  <div class="menuTemplate">
      <div v-if="featuredScreen">
        <featuredParent :featuredScreen="featuredScreen"></featuredParent>
      </div>
      <div v-for="(item, index) in categoryScreens" :key="index">
        <continueSlider
          v-if="(item.sectionType === 'CONTINUEWATCH' || item.sectionType === 'CONTINUEWATCHING') && (item.status == 'ACTIVE' || item.isSectionEnabled)"
          :screen="item"
          :playerInstance="playerInstance"
        ></continueSlider>
        <slider
          v-if="
            (item.sectionType !== 'CONTINUEWATCH' && item.sectionType !== 'CONTINUEWATCHING' && item.sectionType !== 'ADSLOT')  &&
              (item.isSectionEnabled || item.status == 'ACTIVE') &&
              (!item.userTypes ||
                (item.userTypes && item.userTypes.includes('ALL')) ||
                (item.userTypes && item.userTypes.includes(getUserType())))
          "
          :screen="item"
          :id="index"
          :playerInstance="playerInstance"
          :screenName="screens.id"
        ></slider>
        <div v-if="item.sectionType === 'ADSLOT' && (
                (item.userTypes && item.userTypes.includes('ALL')) ||
                (item.userTypes && item.userTypes.includes(getUserType()))) && item.status == 'ACTIVE' " style="text-align: center;margin: 1rem 0rem;" class="scrollerFooter"> 

          <ins class="adsbygoogle"
              style="display:block"
              :data-ad-client="getClientId(item, 'clientad')"
              :data-ad-slot="getClientId(item, null)"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
        </div>
      </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
export default {
  props: {
    screens: {
      type: Object,
    },
    menu: {
      type: [Object, String],
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      enableTemplate: false,
      templates: [],
      featuredScreen: "",
      categoryScreens: [],
      categoryScreensSlot: '',
      localDisplayLang: null,
      showHoveredColor: false,
      subscriptions: [],   
      subscriptionDetails: false
    };
  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "getToken",
      "getRtl",
      "subscriptionList",
      "getFirebaseUserUID",
      "appConfig"
    ]),
  },
  watch: {
    subscriberid(val) {
      if (val) {
        this.renderTemplate();
      }
    },
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });

    eventBus.$on("rerender-template", () => {
      //fetching screens of that particular menu.
      this.renderTemplate();
      //toggling the menu filter component.
      this.renderMenuFilter();
    });

    //fetching screens of that particular menu.
    this.renderTemplate();

    //toggling the menu filter component.
    this.renderMenuFilter();
  },
  mounted() {
 
  
    setTimeout(() => {

      if (localStorage.getItem("subscriptionDetails")) {
        this.subscriptionDetails = JSON.parse(localStorage.getItem("subscriptionDetails"));
      }

      if (
        this.localDisplayLang === "ara" &&
        this.continueWatchData &&
        this.continueWatchData.length < 5
      ) {
        document.getElementById("continueWatch-carousel-iw")
          ? (document.getElementById(
              "continueWatch-carousel-iw"
            ).style.textAlign = "-webkit-right")
          : "";
      }
    }, 700);
  },
  methods: {
    actInit () {
      setTimeout(() => {
       (adsbygoogle = window.adsbygoogle || []).push({});
          setTimeout(() => {
            (adsbygoogle = window.adsbygoogle || []).push({});
          }, 2000);
      }, 6000);
    },
    getClientId(item, type) {
      let adTag = item?.sectionData?.adTag;
      
      if (type == 'clientad') {
        return adTag.split("/")[0]
      } else {
        return adTag.split("/")[1]

      }
    },
    getUserType() {
      if (!this.subscriberid) {
        return "GUEST";
      } else if (this.subscriberid && this.subscriptionDetails) {
        return "SUBSCRIBED";
      } else {
        return "REGISTERED";
      }
    },
    renderTemplateUsingDecking () {
      // this.featuredScreen = this.screens.sections[
      //   this.screens.sections.findIndex((element) => {
      //     return element.sectionType == "FILTER" && element.listType == "CAROUSEL";
      //   })
      // ];
      // this.categoryScreens = this.screens.sections.filter((element) => {
      //   if ((element.sectionType == "FILTER" && element.listType == "LIST") || (element.itemType == "CONTENT" && element.sectionType == "ITEMLIST")  || (element.sectionType == "CONTINUEWATCHING")) {
      //     return element;
      //   }
      // });

      this.screens.sections.forEach((element) => {
          if (element.sectionType == "FILTER" && element.listType == "CAROUSEL") {
            this.featuredScreen = element
          }

          if ((element.sectionType == "FILTER" && element.listType == "LIST") || (element.itemType == "CONTENT" && element.sectionType == "ITEMLIST")  || (element.sectionType == "CONTINUEWATCHING") || element.sectionType == "ADSLOT") {
            this.categoryScreens.push(element)
          }


      })


      this.actInit()

    },
    renderTemplate() {
      if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
          this.renderTemplateUsingDecking();
          return;
      }

      this.featuredScreen = this.screens.sections[
        this.screens.sections.findIndex((element) => {
          return element.sectionType === "FEATURED";
        })
      ];
      this.categoryScreens = this.screens.sections.filter((element) => {
        return element.sectionType !== "FEATURED";
      });
   
    },
    //toggling the menu filter component based on the hasFilter property in each menu object.
    renderMenuFilter() {
      let payload = {};
      if (!this.menu.hasOwnProperty("hasFilter")) {
        payload.state = false;
      } else if (this.menu.hasOwnProperty("hasFilter") && this.menu.hasFilter) {
        payload.state = true;
        payload.menu = this.menu;
      } else if (this.menu === null) {
        payload.state = false;
      } else {
        payload.state = false;
      }
      eventBus.$emit("toggleMenuFilter", payload);
      window.scrollTo(0, 0);
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },

    hoverEffect() {
      this.showHoveredColor = true;
    },

    noHoverEffect() {
      this.showHoveredColor = false;
    },
  },

  beforeDestroy() {
    eventBus.$off("rerender-template");
  },

  components: {
    featuredParent: () =>
      import(
        /* webpackChunkName: "featured" */ "@/components/Carousels/featuredParent.vue"
      ),
    slider: () =>
      import(
        /* webpackChunkName: "carousel" */ "@/components/categorySlider/slider.vue"
      ),
    continueSlider: () =>
      import(
        /* webpackChunkName: "contslider" */ "@/components/categorySlider/continueSlider.vue"
      ),
  },
  // mixins: [FirebaseActions]
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./menuTemplate.scss"
</style>
